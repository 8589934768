export const menuItems = [
    {
        id: 1,
        label: "menuitems.menu.text",
        isTitle: true
    },
    {
        id: 2,
        label: 'Users',
        icon: 'ri-user-line',
        link: '/users'
    },
    {
        id:3,
        label:'Categories',
        icon:'ri-dashboard-line',
        subItems: [
            {
                label: 'Mobile Categories',
                link: '/categories'
            },
            {
                label: 'Fixed Categories',
                link: '/fixed_categories'
            },
        ]

    },


    {
        id: 6,
        label: 'Offers',
        icon: 'ri-bubble-chart-fill',
        subItems:[
            {
                label: 'Mobile',
                link: '/mobile-offer',
            },
            {
                label: 'Fixed',
                link: '/fixed-offer',
            },

        ]
    },
    {
        id: 6,
        label: 'Support',
        icon: 'ri-bubble-chart-fill',
        subItems:[
            {
                label: 'Conversations',
                link: '/chat',
            },
            // {
            //     label: 'Fixed',
            //     link: '/fixed-offer',
            // },

        ]
    },
]