
export function makeToast(title,message,variant) {
    this.$bvToast.toast(message, {
        title: `Toast ${variant || "default"}`,
        variant: variant,
        solid: true
    });
}
export function getUser(){
    return JSON.parse(window.localStorage.user);
}